<!--校区管理-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">辅导员信息管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <el-input
          v-model="keyword"
          placeholder="请输入姓名/教工号"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
        >搜索</el-button>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
        >新增</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_gredit')"
          icon="el-icon-edit"
          @click="showGREdit"
        >编辑个人信息</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
        >编辑带班信息</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delLs"
        >删除</el-button>
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="bmmc"
          label="部门名称"
          width="500"
        >
        </el-table-column>
        <el-table-column
          prop="jgh"
          label="教工号"
          width="500"
        >
        </el-table-column>
        <el-table-column
          prop="xm"
          label="姓名"
          width="500"
        > </el-table-column>
        <el-table-column
          prop="xb"
          label="性别"
        >
          <template slot-scope="scope">
            {{ scope.row.xb == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="forminfo"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="lsinfoRules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="教工号"
              label-width="120px"
              prop="JGH"
            >
              <el-input
                @focus="showLSSel"
                v-model="forminfo.JGH"
                autocomplete="off"
                maxlength="20"
                :disabled="this.operation == 0"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="姓名"
              label-width="120px"
              prop="XM"
            >
              <el-input
                :disabled="true"
                v-model="forminfo.XM"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-table
          border
          ref="bjmultipleTable"
          :data="forminfo.bjlist"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="55"
          > </el-table-column>
          <el-table-column
            prop="bjbh"
            label="班级编号"
          > </el-table-column>
          <el-table-column
            prop="bjmc"
            label="班级名称"
          > </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <el-button
            type="primary"
            @click="showaddbj"
          >添加班级</el-button>
          <el-button
            type="danger"
            @click="delbj"
          >删除班级</el-button>
        </div>
      </el-form>
      <el-dialog
        title="教职工信息(双击选择)"
        :visible.sync="dialogLSVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span class="mr5fcolor42">教工号/姓名:</span>
          <el-input
            v-model="selkeyword"
            placeholder="请输入教工号/姓名"
            size="small"
            style="width: 200px"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getLSPageList"
            style="margin-left: 10px"
          >搜索</el-button>
        </div>
        <el-table
          :data="lsdataPageList"
          :height="300"
          border
          header-cell-class-name="tableStyle"
          @row-dblclick="selLS"
        >
          <el-table-column
            property="jgh"
            label="教工号"
          ></el-table-column>
          <el-table-column
            property="xm"
            label="姓名"
          ></el-table-column>
          <el-table-column
            property="bmmc"
            label="部门"
          ></el-table-column>
        </el-table>
        <div class="pagebox">
          <el-pagination
            @current-change="handleLSCurrentChange"
            :current-page="pagination2.page"
            :page-size="pagination2.rows"
            layout="total, prev, pager, next, jumper"
            :total="pagination2.records"
          >
          </el-pagination>
        </div>
      </el-dialog>

      <el-dialog
        title="班级信息(双击选择)"
        :visible.sync="dialogBJVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span class="mr5fcolor42">班级编号/班级名称:</span>
          <el-input
            v-model="selkeywordbj"
            placeholder="请输入班级编号/班级名称"
            size="small"
            style="width: 200px"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getBJPageList"
            style="margin-left: 10px"
          >搜索</el-button>
        </div>
        <el-table
          :data="bjdataPageList"
          :height="300"
          border
          header-cell-class-name="tableStyle"
          @row-dblclick="selBJ"
        >
          <el-table-column
            property="bjbh"
            label="班级编号"
          ></el-table-column>
          <el-table-column
            property="bjmc"
            label="班级名称"
          ></el-table-column>
        </el-table>
        <div class="pagebox">
          <el-pagination
            @current-change="handleBJCurrentChange"
            :current-page="pagination3.page"
            :page-size="pagination3.rows"
            layout="total, prev, pager, next, jumper"
            :total="pagination3.records"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑信息"
      :visible.sync="dialogEditFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="forminfo"
        v-if="dialogEditFormVisible"
        ref="formRef"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="教工号"
              label-width="120px"
              prop="JGH"
            >
              <el-input
                v-model="forminfo.JGH"
                autocomplete="off"
                maxlength="20"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="姓名"
              label-width="120px"
              prop="XM"
            >
              <el-input
                disabled
                v-model="forminfo.XM"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="心理咨询证书"
              label-width="120px"
              prop="XLZXZS"
            >
              <el-select
                v-model="forminfo.XLZXZS"
                clearable
                style="width: 100%"
              >
                <el-option
                  label="有"
                  :value="1"
                ></el-option>
                <el-option
                  label="无"
                  :value="0"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="从事年限"
              label-width="120px"
              prop="CSXSGLNX"
            >
              <el-input
                v-model="forminfo.CSXSGLNX"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="是否专职"
              label-width="120px"
              prop="SFZZ"
            >
              <el-select
                v-model="forminfo.SFZZ"
                clearable
                style="width: 100%"
              >
                <el-option
                  label="是"
                  :value="1"
                ></el-option>
                <el-option
                  label="否"
                  :value="0"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="毕业院校"
              label-width="120px"
              prop="BYYX"
            >
              <el-input
                v-model="forminfo.BYYX"
                autocomplete="off"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="专业"
              label-width="120px"
              prop="ZY"
            >
              <el-input
                v-model="forminfo.ZY"
                autocomplete="off"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="入职日期"
              label-width="120px"
              prop="RZRQ"
            >
              <el-date-picker
                v-model="forminfo.RZRQ"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="联系电话"
              label-width="120px"
              prop="LXDH"
            >
              <el-input
                v-model="forminfo.LXDH"
                autocomplete="off"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogEditFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveFDY"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getFDYPageList,
  saveFDYBJ,
  getLSPageList,
  getBJPageList,
  getFDYBJInfo,
  deleteFDYInfo,
  saveFDYInfo,
} from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      pagination2: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      pagination3: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      selkeyword: '',
      selkeywordbj: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      lsdataPageList: [],
      bjdataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      bmlist: [],
      forminfo: {
        ID: '',
        JGH: '',
        XM: '',
        XLZXZS: null,
        CSXSGLNX: '',
        SFZZ: null,
        BYYX: '',
        ZY: '',
        RZRQ: null,
        LXDH: '',
        bjlist: [],
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogLSVisible: false,
      dialogBJVisible: false,
      // 表单验证规则对象
      lsinfoRules: {
        JGH: [{ required: true, message: '请输入教工号', trigger: 'blur' }],
      },
      dialogEditFormVisible: false,
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getFDYBJInfo({ jgh: this.jgh }).then((res) => {
          if (res.code === 200) {
            this.forminfo.JGH = res.data.jgh
            this.forminfo.XM = res.data.xm
            this.forminfo.bjlist = res.data.bjlist
          }
        })
        this.operation = 0
        this.dialogFormVisible = true
      }
    },
    showLSSel() {
      if (this.operation === 1) {
        this.dialogLSVisible = true
        this.getLSPageList()
      }
    },
    showaddbj() {
      this.dialogBJVisible = true
      this.getBJPageList()
    },
    scbjh(s) {
      console.log('===')
      console.log(s)
    },
    delbj() {
      var arr1 = this.forminfo.bjlist
      var arr2 = this.$refs.bjmultipleTable.selection
      var tmp = []
      for (var i = 0; i < arr1.length; i++) {
        for (var j = 0; j < arr2.length; j++) {
          if (arr1[i].bjbh === arr2[j].bjbh) {
            break
          }
        }
        if (j === arr2.length) {
          tmp.push(arr1[i])
        }
      }
      this.forminfo.bjlist = tmp
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.forminfo.ID = ''
      } else {
        this.forminfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveFDYBJ(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除部门
    delLs() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteFDYInfo({ jgh: this.jgh }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    handleLSCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination2.page = val
      this.getLSPageList()
    },
    handleBJCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination3.page = val
      this.getBJPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.jgh = ''
      } else {
        this.keyValue = currentRow.id
        this.jgh = currentRow.jgh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getFDYPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    getLSPageList() {
      getLSPageList({
        queryJson: JSON.stringify({ keyword: this.selkeyword }),
        pagination: JSON.stringify(this.pagination2),
      })
        .then((res) => {
          if (res.code === 200) {
            this.pagination2.page = res.data.page
            this.pagination2.total = res.data.total
            this.pagination2.records = res.data.records
            this.lsdataPageList = res.data.rows
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getBJPageList() {
      getBJPageList({
        queryJson: JSON.stringify({ keyword: this.selkeywordbj }),
        pagination: JSON.stringify(this.pagination3),
      })
        .then((res) => {
          if (res.code === 200) {
            this.pagination3.page = res.data.page
            this.pagination3.total = res.data.total
            this.pagination3.records = res.data.records
            this.bjdataPageList = res.data.rows
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selLS(row, column, event) {
      this.forminfo.JGH = row.jgh
      this.forminfo.XM = row.xm
      this.dialogLSVisible = false
    },
    selBJ(row, column, event) {
      for (var i = 0; i < this.forminfo.bjlist.length; i++) {
        if (row.bjbh === this.forminfo.bjlist[i].bjbh) {
          this.$message.error('该班级编号已添加！！')
          return
        }
      }
      this.forminfo.bjlist.push(row)
      this.dialogBJVisible = false
    },
    // 显示编辑弹出框
    showGREdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getFDYBJInfo({ jgh: this.jgh }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.JGH = res.data.jgh
            this.forminfo.XM = res.data.xm
            this.forminfo.XLZXZS = res.data.xlzxzs
            this.forminfo.CSXSGLNX = res.data.csxsglnx
            this.forminfo.SFZZ = res.data.sfzz
            this.forminfo.BYYX = res.data.byyx
            this.forminfo.ZY = res.data.zy
            this.forminfo.RZRQ = res.data.rzrq
            this.forminfo.LXDH = res.data.lxdh
          }
        })
        this.dialogEditFormVisible = true
      }
    },
    saveFDY() {
      saveFDYInfo(this.forminfo).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.dialogEditFormVisible = false
          this.getDataPageList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
